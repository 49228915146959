<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Active Ticket แสดงตามสถานะ"
chart.summary : "มี Ticket ค้างอยู่ทั้งหมด {total} @:common.ticket.unit"
</i18n>

<template>
	<ChartContainer
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<DoughnutChart ref="chart"/>
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{lotName}}</b>
		</span>
	</ChartContainer>
</template>


<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import DoughnutChart from "./general/DoughnutChart.vue"
import _randomColor from "randomcolor"
import ApiError from '@utils/errors/ApiError'
import {ticketStatusColors} from "@utils/chartUtil"


export default {
	components : {
		ChartContainer ,DoughnutChart
	} ,
	props : {
		lotName : {
			type : String,
			default : undefined
		} ,
		lotId : {
			type : String,
			default : null,
		}
	} ,
	data() {
		return {
			total : 0,
			loading : false,
			error : undefined,
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		}
	} ,
	watch :{
		lotId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset) {
			if (!this.$notEmpty(dataset))
				return null

			const statusColors = ticketStatusColors()
			const counted = [];
			const labels = [];
			const colors = [];
			for(const status in dataset) {
				if (dataset[status] <= 0)
					continue;
				counted.push(dataset[status]);
				if (status in statusColors) {
					colors.push(statusColors[status])
				} else {
					colors.push(_randomColor())
				}
				labels.push(this.$tenum('ticket.status',status))
			}

			return {
				datasets : [{
					data : counted,
					backgroundColor : colors
				}] ,
				labels
			}
		} ,
		fetchData() {
			this.reset()
			if (!this.lotId) {
				this.$refs.chart.applyChartData()
				return;
			}

			this.loading = true
			this.error = undefined
			const params ={lotId : this.lotId}
			axios.get("/api/tickets/stat-active-tickets-by-status",{params}).then((response) => {
				const data = response.data.data
				const chartData = {}
				for (const dataset of data.dataset) {
					chartData[dataset.name] = dataset.numTickets
				}
				this.$refs.chart.applyChartData(this.convertDataset(chartData))
				this.total = data.total;
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
