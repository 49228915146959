<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Ticket (ตามวันที่เกิดเหตุ ย้อนหลัง 1 ปี) "
chart.summary : "มีจำนวน Ticket ทั้งหมด {total} @:common.ticket.unit, ขอคำปรึกษา {consult} @:common.ticket.unit"

chart.ticket.num_tickets : "Ticket"
chart.ticket.num_consult : "Consult"
</i18n>

<template>
	<ChartContainer
		ref="container"
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<VerticalBarChart :hide-legend="false" :data="chartData" :chart-options="chartOptions"/>
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{lotName}}</b>
		</span>
	</ChartContainer>
</template>

<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import VerticalBarChart from "./general/VerticalBarChart.vue"
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ChartContainer , VerticalBarChart
	} ,
	props : {
		lotName : {
			type : String,
			default : undefined
		} ,
		lotId : {
			type : String,
			default : undefined,
		}
	} ,
	data() {
		return {
			total : 0,
			totalConsult : 0,
			loading : false,
			error : undefined,

			chartData: {},
			chartOptions: {}
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total,consult : this.totalConsult})
		}
	} ,
	watch :{
		lotId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.totalConsult =0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset, monthLabels) {
			if (!this.$notEmpty(dataset))
				return null
			const labels = ['', ...Object.values(monthLabels)]
			const statData = [0]
			const consultData = [0]

			for (const key in monthLabels) {
				statData.push(key in dataset.numTickets ? dataset.numTickets[key] : 0)
				consultData.push(key in dataset.numConsults ? dataset.numConsults[key] : 0 )
			}

			return {
				labels ,
				datasets : [
					{
						label: this.$t('chart.ticket.num_tickets'),
						backgroundColor : "#1890ff",
						borderColor :  "#1890ff",
						data : statData,
						fill : false,
					} ,
					{
						label: this.$t('chart.ticket.num_consult'),
						backgroundColor : "#ffc45d",
						borderColor :  "#ffc45d",
						data : consultData,
						fill : false,
					}
				]
			}
		} ,
		fetchData() {
			this.reset()
			if (!this.lotId) {
				return;
			}
			const startDate = this.$dayjs().subtract(12,'month').format("YYYY-MM-DD");
			const endDate= this.$dayjs().format("YYYY-MM-DD");

			this.loading = true
			this.error = undefined
			axios.get(`/api/tickets/stat-tickets-by-lots/${this.lotId}`,{params : {startDate, endDate}}).then((response) => {
				const data = response.data.data
				this.total = data.total;
				this.totalConsult = data.totalConsult
				this.chartData = this.convertDataset(data.dataset, data.monthLabels)
				this.chartOptions = {
					scales: {
						yAxes: [
							{
								ticks: {
									min: 0,
									precision: 0,
									suggestedMax: data.maxValue + 3,
								},
							},
						],
					},
				};
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
