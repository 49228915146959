<i18n locale="th" lang="yaml" >
section.note : "บันทึกของ Lot นี้"
section.drone.registered : "สถิติ Drone ที่ลงทะเบียนเรียบร้อย"
section.ticket.stat : "สถิติ Ticket ของ Drone Lot นี้"
section.ticket.progress : "Ticket ที่อยู่ระหว่างดำเนินการ"
</i18n>

<template>
	<div class="page-padding">
		<div class="page-section-title">
			{{$t('section.note')}}
		</div>

		<DroneLotNoteViewer
			class="drone-lot-note"
			:editable="true"
			:lot-id="lot.id"
			:lot-name="lot.lotName"/>

		<template v-if="showNumberOfDrones">
			<div class="page-section-title">
				{{$t('section.drone.registered')}}
			</div>
			<DronesRegisteredChart
				bordered
				style="margin-bottom:32px;"
				class="center-block"
				:container-width="600"
				:lot-id="lot.id"
				:lot-name="lot.lotName"/>
		</template>

		<div class="page-section-title">
			{{$t('section.ticket.stat')}}
		</div>
		<div  class="ticket-stat-pane">
			<TicketsActiveStatusByLotChart
				:container-width="350"
				:lot-id="lot.id"
				:lot-name="lot.lotName"/>
			<TicketsPerMonthByLotChart
				:container-width="600"
				:lot-id="lot.id"
				:lot-name="lot.lotName"/>
		</div>
		<template v-if="canViewAllTickets">
			<div class="page-section-title">
				{{$t('section.ticket.progress')}}
			</div>
			<TicketTableMode
				mode="progress"
				filter-name="drone_lot_id"
				:filter-value="lot.id"/>
		</template>
	</div>
</template>

<script>
import TicketsActiveStatusByLotChart from "@components/chart/TicketsActiveStatusByLotChart.vue"
import TicketsPerMonthByLotChart from "@components/chart/TicketsPerMonthByLotChart.vue"
import DronesRegisteredChart from "@components/chart/DronesRegisteredChart.vue"
import TicketTableMode from "@components/ticket/TicketTableMode.vue"
import DroneLotNoteViewer from "@components/drone/DroneLotNoteViewer.vue"
import PageMixin from "@mixins/PageMixin.vue"

export default {
	components : {
		DronesRegisteredChart, TicketsActiveStatusByLotChart ,TicketsPerMonthByLotChart ,
		TicketTableMode,DroneLotNoteViewer
	} ,
	mixins : [PageMixin],
	props : {
		model : {
			type : null,
			default : () => []
		} ,
		lot : {
			type : null,
			default : () => []
		} ,
	} ,
	computed : {
		showNumberOfDrones() {
			return this.$authorize('listAll','drone')
		} ,
		canViewAllTickets() {
			return this.$authorize('listAll','ticket')
		}
	}
}
</script>
<style lang="less" scoped>
.ticket-stat-pane {
	border : 1px solid @border-color-base;
	padding : 16px;
	display : flex;
	justify-content: space-between;
	align-items : center;
	margin-bottom : 24px;
	.mychart {
		width : 100%;
		height : 100%;
	}
	.tablet &,.mobile & {
		flex-direction: column;
		padding : 8px;
		.mychart {
			margin-bottom : 16px;
		}
	}
}
.drone-lot-note {
	margin : 0 auto 24px;
	min-height : 250px;
	max-width : 500px;
}
</style>
